import { VENDOR_IDS } from '../../../constants/vendor';

export const customWording = {
  [VENDOR_IDS.AG1]: {
    'Press the "Setup" button on the bottom of your scale, then place it empty on a flat surface to set the "zero level".':
      'Press the "Setup" button on the bottom of your smart attachment, then place it empty on a flat surface to set the "zero level".',
    'Press the "Setup" button on the bottom of your scale.':
      'Press the "Setup" button on the bottom of your smart attachment.',
    '/connect-scale/scale-bottom.png': '/connect-scale/ag1/scale-bottom.png',
    '/connect-scale/empty-container.png': '/connect-scale/ag1/smart-canister.png',
  },
};
