import React from 'react';
import { FaqElement } from './FaqElement';
import { useIsAg1 } from '../../hooks/use-is-ag1';

const FAQ = [
  {
    question: "Why didn't I get a pop-up after connecting to the scale?",
    answer: 'Please try navigating to: 192.168.0.1 or 192.168.1.1 (Android) or captive.apple.com (iOS)',
  },
  {
    question: "Why isn't my Wi-Fi network appearing?",
    answer: 'Please exit the pop-up and try syncing again.',
  },
  {
    question: 'What happens after I sync the scale?',
    answer:
      'You should receive a confirmation email or text. Otherwise, please try restarting your router and syncing the scale again. Ensure there are no typos in your email address.',
    excludeAg1: true,
  },
  {
    question: 'How do I change the Wi-Fi?',
    answer: 'Connect to the “Bottomless_Scale” Wi-Fi and select “Sync to New Wi-Fi”.',
  },
  {
    question: "How do I reset my scale's zero level?",
    answer:
      'Remove the item from the scale (or leave your empty container on it), connect to the “Bottomless_Scale” Wi-Fi, and select “Set Zero Level”.',
  },
];

export const ScaleFaq = () => {
  const isAg1 = useIsAg1();

  return (
    <div className="text-center mt-5">
      <h2 className="text-center mb-5">FAQ & Troubleshooting</h2>
      {FAQ.filter(faq => !faq.excludeAg1 || (faq.excludeAg1 && !isAg1)).map(({ question, answer }) => (
        <FaqElement key={question} question={question} answer={answer} />
      ))}
      <div className="mb-5">
        <div className="font-weight-bold mb-3">Need more help?</div>
        <div>
          Contact{' '}
          <strong>
            <a href="mailto:support@bottomless.com" title="E-mail support" className="text-dark text-decoration-none">
              support@bottomless.com
            </a>
          </strong>{' '}
          or text{' '}
          <strong>
            <a href="sms:+12062600782" title="Text support" className="text-nowrap text-dark text-decoration-none">
              (206) 260-0782
            </a>
          </strong>
        </div>
      </div>
    </div>
  );
};
