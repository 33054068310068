import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Check } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import * as Sentry from '@sentry/browser';
import { Box } from '@bottomless/common/components';
import { useQueryString, useVendorLock } from '@bottomless/common/hooks';
import { withMetaTags } from '../../../components/MetaTags/MetaTags';
import { getPortalRedirectAction } from '../../../store';
import { SubscriptionFaq } from './components';
import { ScaleConnection } from '../../../components/ScaleConnection';
import './ConnectSuccessPage.scss';
import { withCustomWording } from '../context/custom-wording-context';
import { customWording } from './customWording';
import { useCustomWording } from '../hooks/use-custom-wording';
import { useIsAg1 } from '../hooks/use-is-ag1';
import { Ag1ScaleImage } from '../components/Ag1ScaleImage';

const ConnectSuccessPageComponent = ({ getPortalRedirect }) => {
  const { cw } = useCustomWording();
  const isAg1 = useIsAg1();
  const history = useHistory();
  const vendor = useVendorLock();
  const [isRedirecting, setRedirecting] = useState(false);

  const { params } = useQueryString();
  const isTare = useMemo(() => params.flow === 'tare', [params]);

  const onClick = useCallback(async () => {
    if (!vendor) {
      return history.push('/profile');
    }

    try {
      const { payload } = await getPortalRedirect();

      window.location.href = payload.url;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      Sentry.captureException(e);
    }

    setRedirecting(true);
  }, [vendor, history, getPortalRedirect]);

  return (
    <div className="page-activate-success">
      <h1 className="text-center mb-4">{cw('Your Scale is Ready to Go!')}</h1>
      <Box secondary className="mb-4">
        <div className="d-flex justify-content-center mb-4">
          <span className="text-white success-checkmark d-flex align-items-center justify-content-center rounded-circle">
            <Check className="mt-1" />
          </span>
        </div>
        <h2 className="text-center mb-4">{isTare ? 'New "zero level" recorded.' : cw('Scale successfully linked.')}</h2>

        {!isTare && (
          <>
            <div className="text-center mb-4">{cw('Place your product on the scale to begin tracking.')}</div>
            <ScaleConnection lines={[true, true, true, true]} ScaleImageComponent={isAg1 ? Ag1ScaleImage : undefined} />
          </>
        )}
        {isTare && (
          <>
            <div className="text-center text-secondary pt-3">Your new weight is:</div>
            <div className="success-checkmark-text display-4 text-center mb-4">
              0.0<span className="text-ounces">oz</span>
            </div>
            <div className="text-center">{cw('Place your product on the scale to begin tracking.')}</div>
          </>
        )}
      </Box>
      {!isTare && !isAg1 && (
        <div className="mb-4 text-center">
          To manage your subscription, view your order history, or adjust settings, proceed to your profile.
        </div>
      )}
      {!isAg1 && (
        <Button size="lg" color="dark" block className="mb-4" onClick={onClick}>
          {isRedirecting ? 'Redirecting...' : 'Go to Profile'}
        </Button>
      )}
      <SubscriptionFaq />
    </div>
  );
};

ConnectSuccessPageComponent.propTypes = {
  getPortalRedirect: PropTypes.func.isRequired,
};

export const ConnectSuccessPage = withCustomWording(
  customWording,
  withMetaTags({
    title: 'Bottomless.com: Activate your smart scale',
  })(
    connect(null, dispatch => ({
      getPortalRedirect: data => dispatch(getPortalRedirectAction(data)),
    }))(ConnectSuccessPageComponent)
  )
);
