import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { Button, Collapse, Container, Nav, Navbar as StrapNavBar, NavbarToggler, NavItem } from 'reactstrap';
import { Link, NavLink, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useVendorLock } from '@bottomless/common/hooks';
import { getMeAction } from '../../store';
import './Navbar.scss';
import { ShopifyCheckoutNavbar } from './ShopifyCheckoutNavbar';

const NavbarComponent = ({
  me,
  getMe,
  small,
  withSignUpButton,
  withCheckoutButton,
  history,
  withShortLinks,
  sticky,
  categorySlug,
  href: rawHref,
  title: rawTitle,
  isShopifyCheckout,
  shopifyVendorLogo,
  heading,
  checkoutVendor,
  useVendorHeaderLink,
  forceNoVendor,
  hideVendor,
  noLink,
}) => {
  const [isOpen, toggle] = useState(false);
  const [{ checkout, authorization }] = useCookies(['checkout', 'authorization']);
  const isMobile = useMediaQuery({ maxDeviceWidth: 767 });

  const answers = useSelector(({ quiz }) => quiz.customRotation);
  const isUnfinishedQuiz = useMemo(() => Object.keys(answers).length > 0, [answers]);

  const href = useMemo(() => {
    if (checkout) {
      return `/get_started/${checkout}`;
    }

    return isUnfinishedQuiz ? '/filter_checkout?finished=true' : rawHref;
  }, [checkout, isUnfinishedQuiz, rawHref]);

  const title = useMemo(() => (checkout || isUnfinishedQuiz ? 'Finish Checkout' : rawTitle), [
    checkout,
    isUnfinishedQuiz,
    rawTitle,
  ]);

  const rawVendor = useVendorLock();
  const vendor = forceNoVendor ? null : rawVendor;

  const { component: LinkComponent, props: linkProps } = useMemo(() => {
    if (noLink) {
      return {
        component: props => <div {...props} />,
        props: {},
      };
    }

    if (useVendorHeaderLink) {
      return {
        component: props => <a {...props} />,
        props: {
          href: vendor?.shopifyBottomlessAppUrl
            ? `${vendor?.shopifyBottomlessAppUrl}/portal-redirect?authorization=${authorization}`
            : vendor?.website,
        },
      };
    }

    return { component: Link, props: { to: categorySlug === 'pet-food' ? '/pets' : '/coffee' } };
  }, [vendor, useVendorHeaderLink, categorySlug, authorization, noLink]);

  useEffect(() => {
    if (!small) {
      getMe();
    }
  }, [getMe, small]);

  const scrollToSignUp = () => window.scrollTo(0, document.querySelector('section.sign-up').offsetTop);

  const onSignUp = () => {
    if (document.querySelector('section.sign-up')) {
      return scrollToSignUp();
    }

    history.push('/filter_checkout');
  };

  if (isShopifyCheckout) {
    return <ShopifyCheckoutNavbar sticky={sticky} shopifyVendorLogo={shopifyVendorLogo} isMobile={isMobile} />;
  }

  return (
    <StrapNavBar color="white" light fixed={sticky ? 'top' : undefined} expand="md" className="app-nav border-bottom">
      <Container
        className={classNames({
          'justify-content-center': small,
          'justify-content-between': !me && withCheckoutButton && (isUnfinishedQuiz || checkout),
        })}
      >
        {!checkoutVendor && (
          <LinkComponent
            {...linkProps}
            className={classNames('d-flex align-items-center navbar-brand flex-grow-1', {
              'mr-0': small,
              'py-0': vendor,
              'no-vendor': !vendor,
            })}
          >
            {!vendor && <img src="/images/Bottomless-logo-blue.png" alt="Bottomless" height="20" />}
            {vendor && (
              <div className="d-flex align-items-center flex-grow-1">
                {!hideVendor && (
                  <>
                    <div className="w-50 text-right">
                      <img src={vendor.logo} alt={vendor.name} className="small-vendor-logo" />
                    </div>
                    <div className="mx-3 logo-divider" />
                  </>
                )}
                <div className={classNames('w-50', { 'w-100 text-center': hideVendor })}>
                  <img
                    src="https://bottomless.imgix.net/powered-by-v2.png"
                    alt="Powered by Bottomless"
                    height="40"
                    className="navigation-powered-by py-1"
                  />
                </div>
              </div>
            )}
          </LinkComponent>
        )}
        {checkoutVendor && (
          <div>
            <div className="d-flex align-items-center flex-grow-1">
              <div className="w-50 text-right">
                <img src={checkoutVendor.img_url} alt={checkoutVendor.vendor_name} className="small-vendor-logo" />
              </div>
              {!isMobile && <div className="mx-3 logo-divider" />}
              {!isMobile && (
                <div className="w-50">
                  <img
                    src="https://bottomless.imgix.net/powered-by-v2.png"
                    alt="Powered by Bottomless"
                    height="25"
                    className="navigation-powered-by"
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {!small && (
          <>
            <NavbarToggler onClick={() => toggle(!isOpen)} />
            <Collapse isOpen={isOpen} navbar>
              <Nav className="ml-auto align-items-center" navbar>
                {!withShortLinks ? (
                  <>
                    <NavItem>
                      <a href="https://careers.bottomless.com/" className="nav-link">
                        Careers
                      </a>
                    </NavItem>
                  </>
                ) : (
                  <>
                    <NavItem>
                      <NavLink to={me ? '/shop' : '/public_shop'} className="nav-link">
                        Shop
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/gifts" className="nav-link">
                        Gifts
                      </NavLink>
                    </NavItem>
                  </>
                )}

                {me && (
                  <NavItem className="ml-2">
                    <NavLink to="/profile" className="border border-dark nav-link">
                      Your Profile <i className="fa fa-long-arrow-right ml-1" />
                    </NavLink>
                  </NavItem>
                )}

                {!me && (
                  <NavItem className="ml-2">
                    <NavLink to="/login" className="border border-dark nav-link">
                      Login
                    </NavLink>
                  </NavItem>
                )}

                {withSignUpButton && !checkout && !me && (
                  <NavItem className="ml-3">
                    <button className="nav-link border border-dark" onClick={onSignUp}>
                      Sign Up
                    </button>
                  </NavItem>
                )}
              </Nav>
            </Collapse>
          </>
        )}
        {!me && withCheckoutButton && (checkout || isUnfinishedQuiz) && (
          <Button color="primary" size="sm" className="ml-2">
            <NavLink to={href}>{title}</NavLink>
          </Button>
        )}
        {heading}
      </Container>
    </StrapNavBar>
  );
};

NavbarComponent.propTypes = {
  me: PropTypes.object,
  getMe: PropTypes.func.isRequired,
  small: PropTypes.bool,
  withSignUpButton: PropTypes.bool,
  history: PropTypes.object.isRequired,
  sticky: PropTypes.bool.isRequired,
  withShortLinks: PropTypes.bool,
  categorySlug: PropTypes.string,
  href: PropTypes.string,
  title: PropTypes.string,
  withCheckoutButton: PropTypes.bool,
  isShopifyCheckout: PropTypes.bool,
  shopifyVendorLogo: PropTypes.string,
  heading: PropTypes.node,
  checkoutVendor: PropTypes.object,
  useVendorHeaderLink: PropTypes.bool,
  forceNoVendor: PropTypes.bool,
  hideVendor: PropTypes.bool,
  noLink: PropTypes.bool,
};

NavbarComponent.defaultProps = {
  small: false,
  withSignUpButton: true,
  sticky: true,
  withShortLinks: false,
  withCheckoutButton: true,
  isShopifyCheckout: false,
  heading: null,
};

export const Navbar = connect(null, dispatch => ({
  getMe: () => dispatch(getMeAction()),
}))(withRouter(NavbarComponent));
