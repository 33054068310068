import holidays from '@date/holidays-us';
const bankHolidays = holidays.bank();
import moment from 'moment';

export const week = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const utcDate = date =>
  new Date((date ? new Date(date).getTime() : Date.now()) + new Date().getTimezoneOffset() * 60 * 1000);

export const localDate = date =>
  new Date((date ? new Date(date).getTime() : Date.now()) - new Date().getTimezoneOffset() * 60 * 1000);

export const daysUntilNextFullfilment = ({ fulfillment_timelines, fromDate }) => {
  if (!fulfillment_timelines) {
    return '---';
  }

  const findDay = (day, index) => {
    if (index > 7) {
      return '7+';
    }

    if (fulfillment_timelines[week[day]]) {
      return index;
    }

    return findDay(day < 6 ? day + 1 : 0, ++index);
  };

  return findDay((fromDate || utcDate()).getDay(), 0);
};

export const getNextVendorFulfillmentDate = ({
  startDate: initialStartDate = new Date(),
  fulfillmentTimelines,
  vendorDownTimes,
}) => {
  if (!fulfillmentTimelines) {
    return null;
  }

  const startDate = initialStartDate;

  const findDay = (date, index) => {
    if (index > 30) {
      return null;
    }

    const day = date.day();
    const startOfTheDay = moment.utc(date).startOf('day');

    const downDay =
      vendorDownTimes &&
      vendorDownTimes.filter(downDay => {
        const formattedDownDate = moment.utc(downDay.down_date).startOf('day');
        return formattedDownDate.isSame(startOfTheDay);
      });

    const isDownDay = downDay && downDay.length ? true : false;

    if (fulfillmentTimelines[week[day]] && !bankHolidays.isHoliday(date.toDate()) && !isDownDay) {
      return date;
    }

    return findDay(date.clone().add(1, 'day'), ++index);
  };

  return findDay(startDate, 0);
};
