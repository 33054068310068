import React, { useCallback, useState } from 'react';
import { FaqElement } from './FaqElement';
import { useIsAg1 } from '../../hooks/use-is-ag1';

const FAQ = [
  {
    question: 'When can I see my usage data?',
    answer: "Wait a few hours for the data to update. If it still doesn't show up, contact our support team.",
    excludeAg1: true,
  },
  {
    question: 'When are orders scheduled?',
    answer:
      'The scale tracks your usage and re-orders for you so your product arrives at the perfect time. Orders are triggered Sunday through Thursday.',
  },
  {
    question: 'Do I have control over my orders?',
    answer: 'Yes. You have control over your orders. You will get an alert before each order is triggered.',
  },
  {
    question: 'What happens if the item falls off the scale?',
    answer: 'The scale waits for at least 24 hours of steady zero readings before re-ordering.',
  },
  {
    question: 'Do I have to sync the scale with every delivery?',
    answer: "Nope! Just put it on the scale. The scale will know when you've received a new order.",
  },
];

export const SubscriptionFaq = () => {
  const isAg1 = useIsAg1();
  const [isOpen, setOpen] = useState(false);

  const onClick = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();

    setOpen(true);
  }, []);

  return (
    <>
      <div className="text-center">
        <a href="#" onClick={onClick}>
          More information about your subscription
        </a>
      </div>
      {isOpen && (
        <div className="text-center">
          <h1 className="text-center mb-5 mt-5">About your subscription</h1>
          {FAQ.filter(faq => !faq.excludeAg1 || (faq.excludeAg1 && !isAg1)).map(({ question, answer }) => (
            <FaqElement key={question} question={question} answer={answer} />
          ))}
        </div>
      )}
    </>
  );
};
