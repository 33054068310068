import React from 'react';
import { Image } from '@bottomless/common/components';

export const Ag1Setup = () => {
  return (
    <div className="text-center mb-4">
      <Image src="/connect-scale/ag1/smart-canister.png" alt="Smart Canister" width="200" useSrcset className="mb-4" />
      <div>
        Please make sure the AG1 canister is <strong>empty</strong> before snapping it onto the smart attachment. You
        may leave the <strong>scoop</strong> inside.
      </div>
      <br />
      <div>You may fix your AG1 canister to the smart attachment after clicking the &quot;setup&quot; button.</div>
    </div>
  );
};

Ag1Setup.propTypes = {};
