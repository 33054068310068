import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Image } from '@bottomless/common/components';
import { Link } from 'react-router-dom';
import { UsingContainer } from './UsingContainer';
import { useCustomWording } from '../../hooks/use-custom-wording';
import { useIsAg1 } from '../../hooks/use-is-ag1';
import { Ag1Setup } from '../../components/Ag1Setup';

export const SetupScale = ({ flow, timestamp }) => {
  const { cw } = useCustomWording();
  const isAg1 = useIsAg1();
  const isNewWifi = useMemo(() => flow === 'new_wifi', [flow]);

  const heading = useMemo(() => (isNewWifi ? 'Sync to new Wi-Fi' : 'Set "zero level"'), [isNewWifi]);

  return (
    <Box secondary className="mb-4">
      <h2 className="text-center mb-4">{heading}</h2>
      <div className="text-center">
        <Image
          src={cw('/connect-scale/scale-bottom.png')}
          alt="Scale setup"
          width="300"
          height={isAg1 ? 220 : 198}
          useSrcset
          className="mb-4"
        />
      </div>
      {!isNewWifi && (
        <div className="mb-4 text-center">
          {cw(
            'Press the "Setup" button on the bottom of your scale, then place it empty on a flat surface to set the "zero level".'
          )}
        </div>
      )}
      {isNewWifi && (
        <div className="mb-4 text-center">{cw('Press the "Setup" button on the bottom of your scale.')}</div>
      )}
      {isAg1 && <Ag1Setup />}
      <Link
        to={`/activate/step3?flow=${flow}&timestamp=${timestamp}`}
        title="Next"
        className="btn btn-lg btn-dark btn-block"
      >
        Next
      </Link>
      {!isNewWifi && !isAg1 && <UsingContainer />}
    </Box>
  );
};

SetupScale.propTypes = {
  flow: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
};
