import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import { Image } from '@bottomless/common/components';
import './Footer.scss';

export const Footer = () => (
  <section className="footer">
    <Container className="w-100">
      <Row className="d-flex flex-lg-row flex-column align-items-lg-start align-items-center">
        <Col xs="12" lg="6" className="d-flex flex-sm-row flex-column">
          <Col xs="12" sm="6" lg="6" className="d-flex flex-column align-items-sm-start align-items-center pl-lg-0">
            <div className="mb-4 font-italic text-uppercase footer-title">Company</div>
            <a href="/open_shop" className="footer-link">
              Shop
            </a>
            <a href="https://careers.bottomless.com" className="footer-link">
              Careers
            </a>
            <a href="/partners" className="footer-link">
              Business
            </a>
            <a href="/terms-of-service.html" className="footer-link">
              Terms of Use
            </a>
            <a href="/privacy.html" className="footer-link">
              Privacy
            </a>
          </Col>
          <Col xs="12" sm="6" lg="6" className="d-flex flex-column align-items-sm-start align-items-center">
            <div className="mb-4 font-italic text-uppercase footer-title">Services</div>
            <a href="/filter_checkout" className="footer-link">
              Build Rotation
            </a>
            <a href="/pets" className="footer-link">
              Pet Food
            </a>
            <a href="/bring-your-own" className="footer-link">
              BYO Products
            </a>
            <a href="/gifts" className="footer-link">
              Gifts
            </a>
          </Col>
        </Col>
        <Col xs="12" lg="6" className="d-flex flex-sm-row flex-column justify-content-between">
          <Col xs="12" sm="6" lg="6" className="d-flex flex-column align-items-sm-start align-items-center">
            <div className="mb-4 font-italic text-uppercase footer-title">social</div>
            <a href="https://www.facebook.com/bottomless/" className="d-flex align-items-center footer-social-link">
              <Image
                src="https://bottomless.imgix.net/home/facebook.svg"
                loading="lazy"
                width="18"
                alt="Facebook Logo"
                className="social-image"
              />
              <div className="footer-link social-link">Facebook</div>
            </a>
            <a href="https://twitter.com/bottomless" className="d-flex align-items-center footer-social-link">
              <Image
                src="https://bottomless.imgix.net/home/twitter.svg"
                loading="lazy"
                width="18"
                alt="Twitter Logo"
                className="social-image"
              />
              <div className="footer-link social-link">Twitter</div>
            </a>
            <a
              href="https://www.instagram.com/bottomless/?hl=en"
              className="d-flex align-items-center footer-social-link"
            >
              <Image
                src="https://bottomless.imgix.net/home/instagram.svg"
                loading="lazy"
                width="18"
                alt="Instagram Logo"
                className="social-image"
              />
              <div className="footer-link social-link">Instagram</div>
            </a>
            <a
              href="https://www.linkedin.com/company/bottomless/"
              className="d-flex align-items-center footer-social-link"
            >
              <Image
                src="https://bottomless.imgix.net/home/linkedin.svg"
                loading="lazy"
                width="18"
                alt="LinkedIn Logo"
                className="social-image"
              />
              <div className="footer-link social-link">LinkedIn</div>
            </a>
          </Col>
          <Col xs="12" sm="6" lg="7" className="d-flex flex-column pr-lg-0 align-items-sm-start align-items-center">
            <div className="mb-4 font-italic text-uppercase footer-title">Contact information</div>
            <a href="mailto:hello@bottomless.com" className="d-flex contact-link">
              <Image
                src="https://bottomless.imgix.net/home/mail.svg"
                loading="lazy"
                width="20"
                alt="email"
                className="contact-image"
              />
              <div className="contact-text">hello@bottomless.com</div>
            </a>
            <div className="d-flex mt-2">
              <Image
                src="https://bottomless.imgix.net/home/map-pin.svg"
                loading="lazy"
                width="20"
                alt="map"
                className="contact-image"
              />
              <div className="contact-text no-link">Seattle, WA</div>
            </div>
            <div className="d-flex mt-2">
              <Image
                src="https://bottomless.imgix.net/home/clock.svg"
                loading="lazy"
                width="20"
                alt="clock"
                className="contact-image"
              />
              <div className="contact-text no-link">
                Monday-Sunday
                <br />
                (8am-10pm)
              </div>
            </div>
          </Col>
        </Col>
      </Row>
      <div className="w-100 footer-divider"></div>
      <div className="d-flex flex-lg-row flex-column justify-content-between align-items-center">
        <a href="/" className="text-lg-left text-center footer-brand">
          <Image
            src="https://bottomless.imgix.net/home/Bottomless-logo-white.png"
            loading="lazy"
            width="206"
            alt="white logo"
            className="w-75"
          />
        </a>
        <div className="footer-copyright">© 2022 Bottomless. All rights reserved</div>
      </div>
    </Container>
  </section>
);

Footer.defaultProps = {
  withLogos: false,
};

Footer.propTypes = {
  withLogos: PropTypes.bool,
};
